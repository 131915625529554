import { computed, inject, Injectable, signal, Signal, WritableSignal } from '@angular/core';

import { Observable, of, tap, finalize, map } from 'rxjs';

import { ClientFeature } from '@core/models/clientFeature';
import { JSONObject } from '@core/models/jsonObject';
import { ApiService } from '@core/services/api.service';
import { TokenService } from '@core/services/auth/token.service';
import { DashboardTokenService } from '@core/services/dashboard-token.service';

export class FeatureFlags {
  public static readonly DIVONO: string = 'divono';
  public static readonly PATIENT_MONITOR: string = 'patient-monitor';
  public static readonly MANV: string = 'manv';
  public static readonly NEF: string = 'nef';
  public static readonly TICKET: string = 'ticket';
  public static readonly EMERGENCY_ROOM: string = 'emergency-room';
}

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private apiService: ApiService = inject(ApiService);
  private tokenService: TokenService = inject(TokenService);
  private dashboardTokenService: DashboardTokenService = inject(DashboardTokenService);

  private fetched: boolean = false;
  private requestInProgress: boolean = false;

  private dashboardConfig: WritableSignal<JSONObject> = signal(null);
  private config: Signal<JSONObject> = computed(
    () => this.dashboardConfig() || (this.tokenService.decodedToken()?.features as JSONObject) || {}
  );

  public featureFlags: Signal<Array<FeatureFlags>> = computed(() => Object.keys(this.config()) || []);

  public getFeatureConfigFromApi(): Observable<JSONObject> {
    const dashboardToken = this.dashboardTokenService.dashboardToken();
    if (!!dashboardToken && !this.fetched && !this.requestInProgress) {
      this.requestInProgress = true;
      return this.apiService.get('/feature/token/' + dashboardToken).pipe(
        map((featureConfig: Array<ClientFeature>) => {
          let config: JSONObject;
          featureConfig
            .filter((feature: ClientFeature) => feature.enabled)
            .forEach((feature: ClientFeature) => {
              const featureIdentifier = feature.featureIdentifier;
              const featureValues = feature.featureValues;
              config = {
                ...config,
                [featureIdentifier]: featureValues
              } as JSONObject;
            });
          return config;
        }),
        tap((featureConfig: JSONObject) => this.dashboardConfig.set(featureConfig)),
        finalize(() => {
          this.fetched = true;
          this.requestInProgress = false;
        })
      );
    } else {
      return of(this.config());
    }
  }

  isFeatureEnabled(key: string): boolean {
    return this.featureFlags().includes(key);
  }
}
